.error-message {
  text-align: center;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding-bottom: 10px;
  p {
    color: #050579;
    font-size: 16px;
  }
  img {
    display: inline-block;
    height: 30%;
    width: 100%;
  }
  button {
    width: 23%;
    margin: 0 auto;
    margin-top: 20px;
    background: white;
    padding: 0px;
    border-color: #050579;
    border-width: thin;
    color: #050579;
    font-weight: bold;
    border-style: double;
    height: 29px;
    border-radius: 11px;
    outline: none;
  }
  @media (min-width: 768px) {
    p {
      font-size: 28px;
    }
    img {
      height: 50%;
      width: 100%;
    }
    button {
      width: 18%;
      height: 38px;
      font-size: 19px;
    }
  }
}
.error-message-container {
  text-align: center;
  padding: 10px 0;
  & > img {
    object-fit: contain;
    height: 435px;
  }
  button {
    cursor: pointer;
  }
}
