.more-info {
  float: left;
  width: 100%;
  background: #f8f8f8;
  box-sizing: border-box;
  margin-top: 20px;
  :global {
    .MuiPaper-elevation1 {
      box-shadow: none;
      border-bottom: 1px solid #d5d5d5;
    }
    .MuiAccordionSummary-content {
      transition: all ease-in-out 0.4s;
      justify-content: center;
      text-transform: uppercase;
      text-align: center;
      p {
        color: #0a0a0a;
      }
    }
    .MuiAccordionSummary-root.Mui-expanded {
      min-height: 45px;
    }
    .MuiAccordionSummary-content.Mui-expanded {
      margin: 0px;
    }
    #area-content {
      border-top: 1px solid #d5d5d5;
    }
    .MuiIconButton-edgeEnd {
      margin-right: 0px;
    }
    .header-icon {
      color: black;
      transition: all ease-in-out 0.25s;
    }
    .column,
    .columns {
      width: 100%;
      float: left;
    }
    .MuiAccordionSummary-expandIcon {
      & span {
        color: #0a0a0a;
      }
    }
    .MuiAccordionSummary-root.Mui-expanded {
      min-height: 45px;
    }
    .MuiAccordionSummary-content.Mui-expanded {
      margin: 0px;
    }
    .MuiAccordionSummary-root {
      padding: 0px 0px;
      margin-top: -4px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
    .categorydescp a {
      color: #2179d0;
      &:hover {
        color: #0078a0;
      }
    }
    a {
      color: #59b5d7;
      text-decoration: none;
    }
    .MuiIconButton-edgeEnd {
      margin-right: 0px;
    }
  }
  table {
      width: 100%;
      tr:nth-child(odd) {
        background: #ccc;
      }
      tr:nth-child(even) {
        background: #f2f2f2;
      }
      th,
      td {
          color: #222222;
          font-size: 12px;
          padding: 5px;
          text-align: center;
          line-height: 20px;
          a {
              color: #222;
          }
      }
  }
}
